import { MenuItem } from "../Types/MenuItem"
import MenuIcon from "./MenuItemIcon"

export const menuData: MenuItem[] = [
	{
		id: "1",
		label: "Front Office",
		level: 1,
		icon: MenuIcon.FrontOffice,
		componentUrl: "/front-office",
		requiredPermission: "view_front_office",
		children: [
			{
				id: "1.1",
				label: "Acenta Yönetimi",
				level: 2,
				icon: MenuIcon.pencil,
				componentUrl: "/front-office/agency-management",
				requiredPermission: "view_agency_management",
				children: [
					{
						id: "1.1.1",
						label: "Acenta Listesi",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/agency-list",
						requiredPermission: "view_agency_list",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_agency" },
							{ id: "btn2", label: "Sil", requiredPermission: "delete_agency" },
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_agency",
							},
						],
					},
					{
						id: "1.1.2",
						label: "Acenta Sözleşme Yönetimi",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/agency-contracts",
						requiredPermission: "manage_agency_contracts",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_agency_contract",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_agency_contract",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_agency_contract",
							},
						],
					},
					{
						id: "1.1.3",
						label: "Acenta Özel Fiyatlandırma (Periyotlar)",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/agency-pricing",
						requiredPermission: "manage_agency_pricing",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_agency_pricing",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_agency_pricing",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_agency_pricing",
							},
						],
					},
					{
						id: "1.1.4",
						label: "İndirim ve Kampanya Yönetimi",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/discounts-campaigns",
						requiredPermission: "manage_discounts",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_discount" },
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_discount",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_discount",
							},
						],
					},
				],
			},
			{
				id: "1.2",
				label: "Satış ve Pazarlama Yönetimi",
				level: 2,
				icon: MenuIcon.pencil,
				componentUrl: "/front-office/sales-marketing",
				requiredPermission: "view_sales_marketing",
				children: [
					{
						id: "1.2.1",
						label: "Rezervasyon ve Gelir Yönetimi",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/reservation-revenue",
						requiredPermission: "manage_reservations",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_reservation",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_reservation",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_reservation",
							},
						],
					},
					{
						id: "1.2.2",
						label: "Kanal Yönetimi",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/channel-management",
						requiredPermission: "view_channel_management",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_channel" },
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_channel",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_channel",
							},
						],
					},
					{
						id: "1.2.3",
						label: "Fiyatlandırma Stratejileri",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/pricing-strategies",
						requiredPermission: "manage_pricing_strategies",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_pricing_strategy",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_pricing_strategy",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_pricing_strategy",
							},
						],
					},
					{
						id: "1.2.4",
						label: "Gelir Tahminleme (Revenue Forecasting)",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/revenue-forecasting",
						requiredPermission: "view_revenue_forecasting",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_forecast" },
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_forecast",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_forecast",
							},
						],
					},
					{
						id: "1.2.5",
						label: "RevPAR, GOPPAR Analizleri",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/revpar-analysis",
						requiredPermission: "view_revpar_analysis",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_revpar_analysis",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_revpar_analysis",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_revpar_analysis",
							},
						],
					},
					{
						id: "1.2.6",
						label: "Satış Raporu",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/sales-report",
						requiredPermission: "view_sales_report",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_sales_report",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_sales_report",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_sales_report",
							},
						],
					},
					{
						id: "1.2.7",
						label: "Kartex Raporu",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/kartex-report",
						requiredPermission: "view_kartex_report",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_kartex_report",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_kartex_report",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_kartex_report",
							},
						],
					},
					{
						id: "1.2.8",
						label: "Doluluk Raporu",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/occupancy-report",
						requiredPermission: "view_occupancy_report",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_occupancy_report",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_occupancy_report",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_occupancy_report",
							},
						],
					},
					{
						id: "1.2.9",
						label: "Rezervasyon Raporları",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/reservation-reports",
						requiredPermission: "view_reservation_reports",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_reservation_report",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_reservation_report",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_reservation_report",
							},
						],
					},
					{
						id: "1.2.10",
						label: "Oda Durum Raporu",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/room-status-report",
						requiredPermission: "view_room_status",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_room_status",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_room_status",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_room_status",
							},
						],
					},
					{
						id: "1.2.11",
						label: "Check-In ve Check-Out Raporu",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/check-in-out-report",
						requiredPermission: "view_check_in_out",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_check_in_out",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_check_in_out",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_check_in_out",
							},
						],
					},
					{
						id: "1.2.12",
						label: "Bütçe Yönetimi",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/budget-management",
						requiredPermission: "manage_budget",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_budget" },
							{ id: "btn2", label: "Sil", requiredPermission: "delete_budget" },
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_budget",
							},
						],
					},
				],
			},
			{
				id: "1.3",
				label: "Rezervasyon Yönetimi",
				level: 2,
				icon: MenuIcon.pencil,
				componentUrl: "/front-office/reservation-management",
				requiredPermission: "view_reservation_management",
				children: [
					{
						id: "1.3.1",
						label: "Rezervasyon Listesi",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/reservation-list",
						requiredPermission: "view_reservation_list",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_reservation",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_reservation",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_reservation",
							},
						],
					},
					{
						id: "1.3.2",
						label: "Grup Rezervasyonları",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/group-reservations",
						requiredPermission: "manage_group_reservations",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_group_reservation",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_group_reservation",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_group_reservation",
							},
						],
					},
					{
						id: "1.3.3",
						label: "Forecast",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/forecast",
						requiredPermission: "view_forecast",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_forecast" },
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_forecast",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_forecast",
							},
						],
					},
					{
						id: "1.3.4",
						label: "Kontenjan Yönetimi",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/quota-management",
						requiredPermission: "manage_quota",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_quota" },
							{ id: "btn2", label: "Sil", requiredPermission: "delete_quota" },
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_quota",
							},
						],
					},
					{
						id: "1.3.5",
						label: "Garantili Satışlar - Forecast",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/guaranteed-sales",
						requiredPermission: "view_guaranteed_sales",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_guaranteed_sale",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_guaranteed_sale",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_guaranteed_sale",
							},
						],
					},
					{
						id: "1.3.6",
						label: "Stop Sale",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/stop-sale",
						requiredPermission: "manage_stop_sale",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_stop_sale",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_stop_sale",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_stop_sale",
							},
						],
					},
					{
						id: "1.3.7",
						label: "Müşteri Profil Kartı",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/customer-profile",
						requiredPermission: "view_customer_profile",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_customer_profile",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_customer_profile",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_customer_profile",
							},
						],
					},
					{
						id: "1.3.8",
						label: "Sadakat Yönetimi",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/loyalty-management",
						requiredPermission: "manage_loyalty",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_loyalty" },
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_loyalty",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_loyalty",
							},
						],
					},
					{
						id: "1.3.9",
						label: "BANKET İşlemleri",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/banquet",
						requiredPermission: "manage_banquet",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_banquet" },
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_banquet",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_banquet",
							},
						],
					},
					{
						id: "1.3.10",
						label: "Toplantı ve Etkinlikler",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/meetings-events",
						requiredPermission: "manage_meetings",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_meeting" },
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_meeting",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_meeting",
							},
						],
					},
				],
			},
			{
				id: "1.4",
				label: "Resepsiyon Yönetimi",
				level: 2,
				icon: MenuIcon.pencil,
				componentUrl: "/front-office/reception-management",
				requiredPermission: "view_reception_management",
				children: [
					{
						id: "1.4.1",
						label: "Günlük Operasyonlar",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/daily-operations",
						requiredPermission: "manage_daily_operations",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_daily_operation",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_daily_operation",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_daily_operation",
							},
						],
					},
					{
						id: "1.4.2",
						label: "Blokaj",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/blockage",
						requiredPermission: "manage_blockage",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_blockage" },
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_blockage",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_blockage",
							},
						],
					},
					{
						id: "1.4.3",
						label: "Room Rack",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/room-rack",
						requiredPermission: "view_room_rack",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_room_rack",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_room_rack",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_room_rack",
							},
						],
					},
					{
						id: "1.4.4",
						label: "Görev Yönetimi - Task Management",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/task-management",
						requiredPermission: "manage_tasks",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_task" },
							{ id: "btn2", label: "Sil", requiredPermission: "delete_task" },
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_task",
							},
						],
					},
					{
						id: "1.4.5",
						label: "KBS Yönetimi",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/kbs-management",
						requiredPermission: "manage_kbs",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_kbs" },
							{ id: "btn2", label: "Sil", requiredPermission: "delete_kbs" },
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_kbs",
							},
						],
					},
					{
						id: "1.4.6",
						label: "Fiyatlar",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/prices",
						requiredPermission: "manage_prices",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_price" },
							{ id: "btn2", label: "Sil", requiredPermission: "delete_price" },
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_price",
							},
						],
					},
					{
						id: "1.4.7",
						label: "Kur İşlemleri",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/currency-operations",
						requiredPermission: "manage_currency",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_currency" },
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_currency",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_currency",
							},
						],
					},
					{
						id: "1.4.8",
						label: "Oda Planlama Tablosu",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/room-planning",
						requiredPermission: "view_room_planning",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_room_planning",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_room_planning",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_room_planning",
							},
						],
					},
				],
			},
			{
				id: "1.5",
				label: "Ön Kasa Yönetimi",
				level: 2,
				icon: MenuIcon.pencil,
				componentUrl: "/front-office/front-cash-management",
				requiredPermission: "view_cash_management",
				children: [
					{
						id: "1.5.1",
						label: "Ön Büro Faturalama",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/front-office-billing",
						requiredPermission: "manage_billing",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_billing" },
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_billing",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_billing",
							},
						],
					},
					{
						id: "1.5.2",
						label: "Kasa İşlemleri",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/cash-operations",
						requiredPermission: "manage_cash_operations",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_cash_operation",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_cash_operation",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_cash_operation",
							},
						],
					},
					{
						id: "1.5.3",
						label: "Hesaplar Listesi",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/accounts-list",
						requiredPermission: "view_accounts",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_account" },
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_account",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_account",
							},
						],
					},
					{
						id: "1.5.4",
						label: "Ekstra Satışlar",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/extra-sales",
						requiredPermission: "manage_extra_sales",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_extra_sale",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_extra_sale",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_extra_sale",
							},
						],
					},
				],
			},
			{
				id: "1.6",
				label: "Fatura Yönetimi",
				level: 2,
				icon: MenuIcon.pencil,
				componentUrl: "/front-office/invoice-management",
				requiredPermission: "view_invoice_management",
				children: [
					{
						id: "1.6.1",
						label: "Fatura Listesi",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/invoice-list",
						requiredPermission: "view_invoice_list",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_invoice" },
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_invoice",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_invoice",
							},
						],
					},
					{
						id: "1.6.2",
						label: "Proforma",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/proforma",
						requiredPermission: "manage_proforma",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_proforma" },
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_proforma",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_proforma",
							},
						],
					},
					{
						id: "1.6.3",
						label: "Bavel Faturalama",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/bavel-billing",
						requiredPermission: "manage_bavel_billing",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_bavel_billing",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_bavel_billing",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_bavel_billing",
							},
						],
					},
					{
						id: "1.6.4",
						label: "Giden Faturalar",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/outgoing-invoices",
						requiredPermission: "view_outgoing_invoices",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_outgoing_invoice",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_outgoing_invoice",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_outgoing_invoice",
							},
						],
					},
					{
						id: "1.6.5",
						label: "Gelen Faturalar",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/incoming-invoices",
						requiredPermission: "view_incoming_invoices",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_incoming_invoice",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_incoming_invoice",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_incoming_invoice",
							},
						],
					},
					{
						id: "1.6.6",
						label: "Fatura Arşivi",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/invoice-archive",
						requiredPermission: "view_invoice_archive",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_invoice_archive",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_invoice_archive",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_invoice_archive",
							},
						],
					},
				],
			},
			{
				id: "1.7",
				label: "Kat Hizmetleri",
				level: 2,
				icon: MenuIcon.pencil,
				componentUrl: "/front-office/housekeeping",
				requiredPermission: "view_housekeeping",
				children: [
					{
						id: "1.7.1",
						label: "Genel Durum",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/general-status",
						requiredPermission: "view_general_status",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_general_status",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_general_status",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_general_status",
							},
						],
					},
					{
						id: "1.7.2",
						label: "Oda İşlemleri",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/room-operations",
						requiredPermission: "manage_room_operations",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_room_operation",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_room_operation",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_room_operation",
							},
						],
					},
					{
						id: "1.7.3",
						label: "Minibar İşlemleri",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/minibar-operations",
						requiredPermission: "manage_minibar",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_minibar" },
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_minibar",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_minibar",
							},
						],
					},
					{
						id: "1.7.4",
						label: "Oda Değişimleri",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/room-changes",
						requiredPermission: "manage_room_changes",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_room_change",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_room_change",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_room_change",
							},
						],
					},
					{
						id: "1.7.5",
						label: "Turn Down Hizmeti",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/turn-down-service",
						requiredPermission: "manage_turn_down",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_turn_down",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_turn_down",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_turn_down",
							},
						],
					},
					{
						id: "1.7.6",
						label: "Kayıp Eşya İşlemleri",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/lost-and-found",
						requiredPermission: "manage_lost_and_found",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_lost_and_found",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_lost_and_found",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_lost_and_found",
							},
						],
					},
					{
						id: "1.7.7",
						label: "Kat Şefi ve Maid Raporları",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/supervisor-maid-reports",
						requiredPermission: "view_supervisor_reports",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_supervisor_report",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_supervisor_report",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_supervisor_report",
							},
						],
					},
				],
			},
			{
				id: "1.8",
				label: "Gün Sonu İşlemleri",
				level: 2,
				icon: MenuIcon.pencil,
				componentUrl: "/front-office/end-of-day",
				requiredPermission: "view_end_of_day",
				children: [
					{
						id: "1.8.1",
						label: "Gün Sonu / Day Shift",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/day-shift",
						requiredPermission: "manage_day_shift",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_day_shift",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_day_shift",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_day_shift",
							},
						],
					},
					{
						id: "1.8.2",
						label: "Yedekleme İşlemleri",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/backup-operations",
						requiredPermission: "manage_backup",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_backup" },
							{ id: "btn2", label: "Sil", requiredPermission: "delete_backup" },
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_backup",
							},
						],
					},
				],
			},
			{
				id: "1.9",
				label: "Operasyonel Rapor Yönetimi",
				level: 2,
				icon: MenuIcon.pencil,
				componentUrl: "/front-office/operational-reports",
				requiredPermission: "view_operational_reports",
				children: [
					{
						id: "1.9.1",
						label: "Yönetici Özeti Raporu",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/executive-summary-report",
						requiredPermission: "view_executive_summary",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_executive_summary",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_executive_summary",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_executive_summary",
							},
						],
					},
					{
						id: "1.9.2",
						label: "Benchmarking Raporu",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/benchmarking-report",
						requiredPermission: "view_benchmarking",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_benchmarking",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_benchmarking",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_benchmarking",
							},
						],
					},
					{
						id: "1.9.3",
						label: "Trend Analizi Raporu",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/trend-analysis",
						requiredPermission: "view_trend_analysis",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_trend_analysis",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_trend_analysis",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_trend_analysis",
							},
						],
					},
					{
						id: "1.9.4",
						label: "Misafir Geri Bildirim Raporu",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/guest-feedback-report",
						requiredPermission: "view_guest_feedback",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_guest_feedback",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_guest_feedback",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_guest_feedback",
							},
						],
					},
					// Diğer raporlar buraya eklenir
				],
			},
			{
				id: "1.10",
				label: "Front Office Ayarları",
				level: 2,
				icon: MenuIcon.pencil,
				componentUrl: "/front-office/settings",
				requiredPermission: "view_front_office_settings",
				children: [
					{
						id: "1.10.1",
						label: "Ayarlar",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/settings/general",
						requiredPermission: "manage_settings",
						requiredPermissions: [
							{ id: "btn1", label: "Ekle", requiredPermission: "add_settings" },
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_settings",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_settings",
							},
						],
					},
					{
						id: "1.10.2",
						label: "Müşteri Tipi Tanımlama",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/settings/customer-type",
						requiredPermission: "manage_customer_type",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_customer_type",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_customer_type",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_customer_type",
							},
						],
					},

					{
						id: "1.10.3",
						label: "Konaklama Tipi Tanımlama",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/settings/acommodation-type",
						requiredPermission: "manage_acommodation-type",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_acommodation-type",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_acommodation-type",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_acommodation-type",
							},
						],
					},
					{
						id: "1.10.4",
						label: "Oda Tipi Tanımlama",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/front-office/settings/room-type",
						requiredPermission: "room-type",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_room_type",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_room_type",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_room_type",
							},
						],
					},
				],
			},
		],
	},
	{
		id: "2",
		label: "Muhasebe",
		level: 1,
		icon: MenuIcon.Muhasebe,
		componentUrl: "/accounting-and-finance",
		requiredPermission: "view_accounting_and_finance",
	},
	{
		id: "3",
		label: "Stok / Cost",
		level: 1,
		icon: MenuIcon.Stok,
		componentUrl: "/stock-cost",
		requiredPermission: "view_stock_cost",
	},
	{
		id: "4",
		label: "Satınalma",
		level: 1,
		icon: MenuIcon.SatinAlma,
		componentUrl: "/purchase",
		requiredPermission: "view_purchase",
	},
	{
		id: "5",
		label: "Demirbaş",
		level: 1,
		icon: MenuIcon.Demirbas,
		componentUrl: "/equipment",
		requiredPermission: "view_equipment",
	},
	{
		id: "6",
		label: "Personel",
		level: 1,
		icon: MenuIcon.Personel,
		componentUrl: "/personnel-management",
		requiredPermission: "view_personnel_management",
	},
	{
		id: "7",
		label: "CRM",
		level: 1,
		icon: MenuIcon.Crm,
		componentUrl: "/crm",
		requiredPermission: "view_crm",
	},
	{
		id: "8",
		label: "POS",
		level: 1,
		icon: MenuIcon.Pos,
		componentUrl: "/pos",
		requiredPermission: "view_pos",
	},
	{
		id: "9",
		label: "Bakım  ",
		level: 1,
		icon: MenuIcon.Bakim,
		componentUrl: "/maintenance-technical",
		requiredPermission: "view_maintenance_technical",
	},
	{
		id: "10",
		label: "Kalite",
		level: 1,
		icon: MenuIcon.Kalite,
		componentUrl: "/quality-management",
		requiredPermission: "view_quality_management",
	},

	{
		id: "999",
		label: "Ayarlar",
		level: 1,
		icon: MenuIcon.Ayarlar,
		componentUrl: "/settings",
		requiredPermission: "view_settings",
		children: [
			{
				id: "999.1",
				label: "Genel Ayarlar",
				level: 2,
				icon: MenuIcon.Ayarlar,
				componentUrl: "/settings/general-settings",
				requiredPermission: "view_general_settings",
				children: [
					{
						id: "999.1.1",
						label: "Sistem Ayarları",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/settings/general-settings/system-settings",
						requiredPermission: "view_system_settings",
						requiredPermissions: [
							{
								id: "btn1",
								label: "Ekle",
								requiredPermission: "add_system_settings",
							},
							{
								id: "btn2",
								label: "Sil",
								requiredPermission: "delete_system_settings",
							},
							{
								id: "btn3",
								label: "Güncelle",
								requiredPermission: "update_system_settings",
							},
						],
					},
					{
						id: "999.1.2",
						label: "Kullanıcı Kontrolleri",
						level: 3,
						icon: MenuIcon.pencil,
						componentUrl: "/settings/general-settings/user-controls",
						requiredPermission: "view_user_controls",
						requiredPermissions: [],
					},
				],
			},
		],
	},
]

// bunlar ana moduller level 1 e eklicez hepsini
// * Muhasebe ve Finans
// * * Stok / Cost
// * Satınalma
// * Demirbaş
// * Personel Yönetimi
// * CRM
// * POS
// * Bakım ve Teknik
// * Kalite Yönetimi
// * Ayarlar
