const apiPath = {
	getUserByGroupAndHotelId: "/api/Kullanici/GetByGroupAndHotelId",
	getRoller: "/api/Roller/list",
	getYetkiler: "/api/Roller/get-yetkiler",
	saveRole: "/api/Roller/save",
	getYetki: "/api/Roller/get-yetkiler",
	addUser: "/api/Kullanici/CreateOrUpdate",
	GetHotel: "/api/Kullanici/GetHotelsByGroup",
	checkRoleAssigned: "/api/Kullanici/CheckRoleAssigned",
	deleteRole: "/api/Roller/delete",
	musteriTipiList: "/api/Kodlar/list",
	musteriTipiUpsert: "/api/Kodlar/upsert",
	konaklamaTipiList: "api/Kodlar/list",
	konaklamaTipiUpsert: "api/Kodlar/upsert",
	odaTipiList: "api/Kodlar/list",
	odaTipiUpsert: "api/Kodlar/upsert",

	// Diğer API yollarını buraya ekleyebilirsiniz
}

export default apiPath
