import React from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { useTheme } from '../../Context/ThemeContext';
import { useAuth } from '../../Context/AuthContext/AuthContext';
import HotelSelect from '../../Context/HotelContext/HotelSelect';
import aksiyonlogo from '../../assets/images/aksiyonlogo.png';
import { IoLogOutOutline } from "react-icons/io5";
import { usePermissions } from '../../Context/PermissionContext/PermissionContext';

interface HeaderProps {
    toggleMenuState: () => void;
}

const Header: React.FC<HeaderProps> = ({ toggleMenuState }) => {
    const { themeColors } = useTheme(); // Theme'den renkleri alıyoruz
    const {logout} = useAuth();
    const {resetPermissions}=usePermissions();


    const handleLogout = async() => {
        await logout();
        await resetPermissions();

    }

    return (
        <div
            className="flex items-center p-4 shadow-md "
            style={{
                backgroundColor: themeColors.bg1, // Arka plan rengini theme'den alıyoruz
                color: themeColors.color,         // Yazı rengini theme'den alıyoruz
                boxShadow: `${themeColors.boxShadow}`, // Gölge rengini theme'den alıyoruz
                position:'relative',
                zIndex:'1000'
            }}
        >
            <button onClick={toggleMenuState} className="mr-4">
                <AiOutlineMenu size={24} />
            </button>
            {/* <img src={aksiyonlogo} alt="aksiyon logo" className="w-42 h-7 mr-4" /> */}
            <HotelSelect />

            <button onClick={handleLogout} style={{
                position:"absolute", right:40
            }}> <IoLogOutOutline style={{fontSize:28}}/> </button>

        </div>
    );
};

export default Header;
