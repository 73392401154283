import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { useAuth } from "../AuthContext/AuthContext";

interface Hotel {
  otelId: number;
  otelAdi: string;
  key: string;
}

interface HotelContextType {
  hotels: Hotel[];
  selectedHotel: Hotel | null;
  setSelectedHotel: (hotel: Hotel) => void;
}

const HotelContext = createContext<HotelContextType | undefined>(undefined);

export const HotelProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuth(); // isAuthenticated'i alın guncelleme için eklendi
  const [hotels, setHotels] = useState<Hotel[]>([]);
  const [selectedHotel, setSelectedHotel] = useState<Hotel | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!isAuthenticated) return; // Kullanıcı giriş yapmadıysa fetchData'yı çalıştırma guncelleme için eklendi

      await new Promise(resolve => setTimeout(resolve, 500)); // 100ms gecikme simülasyonu
  
      const permissions = localStorage.getItem("authPermissions");
      const savedHotelId = localStorage.getItem("selectedHotelId");
  
      if (permissions) {
        const parsedPermissions: Hotel[] = JSON.parse(permissions);
        setHotels(parsedPermissions);
  
        if (savedHotelId) {
          const hotel = parsedPermissions.find(h => h.otelId === parseInt(savedHotelId, 10));
          if (hotel) {
            setSelectedHotel(hotel);
          } else if (parsedPermissions.length > 0) {
            setSelectedHotel(parsedPermissions[0]);
          }
        } else if (parsedPermissions.length > 0) {
          setSelectedHotel(parsedPermissions[0]);
        }
      }
    };
  
    fetchData();
  }, [isAuthenticated]);//yapılan guncelleme burası boştu 
  
  
    const handleSetSelectedHotel = (hotel: Hotel) => {
      setSelectedHotel(hotel);
      localStorage.setItem("selectedHotelId", hotel.otelId.toString());
    };
  
    return (
      <HotelContext.Provider value={{ hotels, selectedHotel, setSelectedHotel: handleSetSelectedHotel }}>
        {children}
      </HotelContext.Provider>
    );
  };
  
  export const useHotel = () => {
    const context = useContext(HotelContext);
    if (!context) {
      throw new Error("useHotel must be used within a HotelProvider");
    }
    return context;
  };
  // guncel bir yapı var kontorl edilciek 
//https://chatgpt.com/c/675fd1bd-b040-800e-8f0a-2d9676d4f239