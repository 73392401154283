import createAxiosInstance from "./axiosInstance";

// API POST isteği göndermek için genel bir fonksiyon
export const postRequest = async <T>(
  apiType: string, // Hangi API kullanılacak
  endpoint: string,
  data: T,
  extraHeaders?: Record<string, string> // Ekstra header bilgileri
): Promise<any> => {
  try {
    const axiosInstance = createAxiosInstance(apiType, extraHeaders); // İlgili API için axios instance oluştur
    const response = await axiosInstance.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error(`POST isteği sırasında hata oluştu: ${endpoint}`, error);
    throw error;
  }
};