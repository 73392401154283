import React, { useState, useEffect } from "react";
import { RxMagicWand } from "react-icons/rx";
import { useTheme } from "../../Context/ThemeContext";
import { AiOutlineClose } from "react-icons/ai";

const colorPalette = [
  "#00365a",
  "#145388",
  "#008ecc",
  "#576a3d",
  "#6fb327",
  "#922c88",
  "#ed7117",
  "#900604",
  "#c0a145",
  "#48494b",
  "#8f8f8f",
  "#3a3a3a",
];

const ThemePanel: React.FC = () => {
  const {
    themeColors,
    setColor,
    setHoverColor,
    toggleTheme,
    borderRadius,
    setBorderRadius,
    isDarkMode,
  } = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState(500);
  const [panelPosition, setPanelPosition] = useState(100);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string | null>(
    localStorage.getItem("selectedColor") || null
  );
  const [selectedHoverColor, setSelectedHoverColor] = useState<string | null>(
    localStorage.getItem("selectedHoverColor") || null
  );

  useEffect(() => {
    if (selectedColor) {
      setColor(selectedColor);
    }
    if (selectedHoverColor) {
      setHoverColor(selectedHoverColor);
    }
    // Bu işlemi yalnızca ilk render'da bir kez yap
    // yani bağımlılık dizisini boş bıraktık.
  }, []);

  const togglePanel = () => {
    if (!isDragging) {
      const adjustedPosition = Math.max(
        20,
        Math.min(window.innerHeight - 400, position - 100)
      );
      setPanelPosition(adjustedPosition);
      setIsOpen(!isOpen);
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (isOpen) return;
    e.preventDefault();
    setIsDragging(false);
    const startY = e.clientY;
    const startPosition = position;

    const onMouseMove = (moveEvent: MouseEvent) => {
      const deltaY = moveEvent.clientY - startY;
      if (Math.abs(deltaY) > 5) {
        setIsDragging(true);
      }
      setPosition(
        Math.max(20, Math.min(window.innerHeight - 60, startPosition + deltaY))
      );
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const handleSetColor = (color: string) => {
    setColor(color);
    setSelectedColor(color);
    localStorage.setItem("selectedColor", color); // Renk seçimini kaydet
  };

  const handleSetHoverColor = (hoverColor: string) => {
    setHoverColor(hoverColor);
    setSelectedHoverColor(hoverColor);
    localStorage.setItem("selectedHoverColor", hoverColor); // Hover rengi kaydet
  };

  const getLighterShade = (color: string) => {
    const colorValue = parseInt(color.slice(1), 16);
    const r = Math.min(255, (colorValue >> 16) + 30);
    const g = Math.min(255, ((colorValue >> 8) & 0x00ff) + 30);
    const b = Math.min(255, (colorValue & 0x0000ff) + 30);
    return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;
  };
  
  return (
    <div className="fixed right-4 flex flex-col items-end select-none">
      <button
        onMouseDown={handleMouseDown}
        onClick={togglePanel}
        style={{
          top: `${Math.max(20, Math.min(window.innerHeight - 60, position))}px`,
          right: isOpen ? "18.23rem" : "4px",
          backgroundColor: themeColors.bg1,
          borderColor: themeColors.color,
          color: themeColors.color,
        }}
        className="fixed w-6 h-8 flex items-center justify-center rounded border shadow-lg transition-transform duration-300 hover:scale-110 cursor-move"
      >
        <RxMagicWand size={16} />
      </button>

      {isOpen && (
        <div
          className={`fixed w-72 p-6 ${
            borderRadius === "rounded" ? "rounded-lg" : ""
          } transform transition-transform duration-300 ease-out`}
          style={{
            top: `${panelPosition}px`,
            right: "4px",
            animation: "slide-in-right 0.3s ease-out",
            maxHeight: `${window.innerHeight - panelPosition - 20}px`,
            boxShadow: themeColors.boxShadow,
            backgroundColor: themeColors.bg2,
            color: themeColors.color,
          }}
        >
          <button
            onClick={() => setIsOpen(false)}
            style={{ color: themeColors.hover }}
            className="absolute top-4 right-4 hover:text-opacity-70"
          >
            <AiOutlineClose size={20} />
          </button>

          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold mb-2">Theme</h3>
              <div className="flex gap-4">
                <button
                  onClick={() => toggleTheme(false)}
                  className={`p-2 rounded `}
                  style={{
                    backgroundColor:!isDarkMode ? themeColors.bg1:themeColors.bg2,
                    color: themeColors.color,
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  Light Theme
                </button>
                <button
                  onClick={() => toggleTheme(true)}
                  className={`p-2 rounded ${
                    isDarkMode ? "bg-blue-500 text-white" : "bg-gray-200"
                  }`}
                  style={{
                    backgroundColor: isDarkMode
                      ? themeColors.bg1
                      : themeColors.bg2,
                    color: isDarkMode ? themeColors.color : themeColors.hover,
                  }}
                >
                  Dark Theme
                </button>
              </div>
            </div>
            {/* burada değişikliik yapıcazz  */}

            <div>
              <h3 className="text-lg font-semibold mb-2">Text Colors</h3>
              <div className="flex flex-wrap gap-2">
                {colorPalette.map((color) => (
                  <div
                    key={color}
                    className="w-5 h-5 rounded-full cursor-pointer"
                    style={{
                      backgroundColor:
                        selectedColor === color ? "#FFFFFF" : color,
                      border: `2px solid ${getLighterShade(color)}`,
                    }}
                    onClick={() => handleSetColor(color)}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor = "#FFFFFF")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        selectedColor === color ? "#FFFFFF" : color)
                    }
                  />
                ))}
              </div>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-2">Hover Colors</h3>
              <div className="flex flex-wrap gap-2">
                {colorPalette.map((hoverColor) => (
                  <div
                    key={hoverColor}
                    className="w-5 h-5 rounded-full cursor-pointer"
                    style={{
                      backgroundColor:
                        selectedHoverColor === hoverColor
                          ? "#FFFFFF"
                          : hoverColor,
                      border: `2px solid ${getLighterShade(hoverColor)}`,
                    }}
                    onClick={() => handleSetHoverColor(hoverColor)}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor = "#FFFFFF")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        selectedHoverColor === hoverColor
                          ? "#FFFFFF"
                          : hoverColor)
                    }
                  />
                ))}
              </div>
            </div>

            {/* burada değişikliik yapıcazz  */}

            <div>
              <h3 className="text-lg font-semibold mb-2">Border Radius</h3>
              <div className="flex items-center gap-4">
                <label className="flex items-center gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="border-radius"
                    className="form-radio"
                    checked={borderRadius === "rounded"}
                    onChange={() => setBorderRadius("rounded")}
                  />
                  <span>Rounded</span>
                </label>
                <label className="flex items-center gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="border-radius"
                    className="form-radio"
                    checked={borderRadius === "flat"}
                    onChange={() => setBorderRadius("flat")}
                  />
                  <span>Flat</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ThemePanel;
