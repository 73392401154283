import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard";
import { ThemeProvider, useTheme } from "./Context/ThemeContext";
import ThemePanel from "./Components/ThemePanel/ThemePanel";
import Loader from "./Components/Loader/Loader";
import { AuthProvider, useAuth } from "./Context/AuthContext/AuthContext";
import LoginPage from "./Pages/LoginPage/LoginPage";
import {
  SessionTimeoutProvider,
  useSessionTimeout,
} from "./Context/SessionTimeoutContext/SessionTimeoutContext";
import { HotelProvider } from "./Context/HotelContext/HotelContext";
import { PermissionProvider } from "./Context/PermissionContext/PermissionContext";

const App: React.FC = () => (
  <ThemeProvider>
    <AuthProvider>
      <HotelProvider>
        <PermissionProvider>
        <SessionTimeoutProvider>
          <ThemedApp />
        </SessionTimeoutProvider>
        </PermissionProvider>
      </HotelProvider>
    </AuthProvider>
  </ThemeProvider>
);

const ThemedApp: React.FC = () => {
  const { setLogoutTime } = useSessionTimeout();
  const { themeColors } = useTheme();
  const { isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const handleFinishLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const logoutTime = null;
    setLogoutTime(logoutTime);
    // console.log(`Logout time set to: ${logoutTime}`);
  }, []);

  return (
    <div
      style={{ height: "100vh", width: "100vw", background: themeColors.bg2 }}
    >
      {isLoading ? (
        <Loader onFinish={handleFinishLoading} />
      ) : isAuthenticated ? (
        <Router>
          <Dashboard />
          <ThemePanel />
        </Router>
      ) : (
        <LoginPage />
      )}
    </div>
  );
};

export default App;
