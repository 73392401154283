import React, { useState, useRef, useEffect } from "react";
import { ChevronDown, Check } from "lucide-react";
import { useTheme } from "../../Context/ThemeContext";

interface DropdownProps {
  selected: string | null;
  options: Array<{ id: number; label: string }>;
  onSelect: (id: number) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ selected, options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openDirection, setOpenDirection] = useState<"down" | "up">("down");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { themeColors, borderRadius } = useTheme();

  const handleSelect = (id: number) => {
    onSelect(id);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      // Kontrol: Alt kısımda yer var mı?
      if (rect.bottom + 200 > viewportHeight) {
        setOpenDirection("up");
      } else {
        setOpenDirection("down");
      }
    }
  }, [isOpen]);

  return (
    <div
      className="text-sm"
      style={{ position: "relative", width: "150px" }}
      ref={dropdownRef}
    >
      {/* Dropdown Header */}
      <div
        onClick={() => setIsOpen(!isOpen)}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2px 4px",
          border: `1px solid ${themeColors.bg3}`,
          borderRadius: borderRadius === "rounded" ? "4px" : "0px",
          cursor: "pointer",
          backgroundColor: themeColors.bg2,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <span>{selected || "Choose Response"}</span>
        <ChevronDown
          size={16}
          style={{
            transform: isOpen ? "rotate(180deg)" : "rotate(0)",
            transition: "transform 0.3s",
          }}
        />
      </div>

      {/* Dropdown Menu */}
      <ul
        style={{
          position: "absolute",
          top: openDirection === "down" ? "110%" : "auto",
          bottom: openDirection === "up" ? "110%" : "auto",
          left: 0,
          width: "100%",
          margin: 0,
          padding: "4px 4px",
          border: `1px solid ${themeColors.bg3}`,
          borderRadius: borderRadius === "rounded" ? "4px" : "0px",
          backgroundColor: themeColors.bg1,
          listStyleType: "none",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
          overflow: "hidden",
          maxHeight: isOpen ? "200px" : "0",
          opacity: isOpen ? 1 : 0,
          transition: "max-height 0.3s ease, opacity 0.3s ease",
        }}
      >
        {options.map((option) => (
          <li
            key={option.id}
            onClick={() => handleSelect(option.id)}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "2px 4px",
              cursor: "pointer",
              backgroundColor:
                selected === option.label ? themeColors.bg2 : "transparent",
              borderRadius: "4px",
              transition: "background-color 0.2s",
              color: selected === option.label ? themeColors.hover : "inherit",
            }}
          >
            {/* Sabit genişlikli tik alanı */}
            <span
              style={{
                width: "20px", // Tik simgesi için sabit genişlik
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {selected === option.label && (
                <Check size={16} color={themeColors.hover} />
              )}
            </span>
            <span style={{ flexGrow: 1 }}>{option.label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
