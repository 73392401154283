import styled, { css,keyframes  } from "styled-components";

interface ScroolbarProps {
  color?: string;
  hoverColor?: string;
  borderColor?: string;
}

export const Scroolbar = styled.div<ScroolbarProps>`

  &::-webkit-scrollbar {
    width: 10px; // Kaydırma çubuğu genişliği
    height: 10px; // Kaydırma çubuğu yüksekliği
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0; // Kaydırma çubuğu arka planı
        right: 8px;

  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; // Kaydırma çubuğu rengi
    border-radius: 6px; // Köşe yuvarlama
    border: 3px solid #f0f0f0; // Dış boşluk
        cursor: pointer; // Kaydırma çubuğu imleci

  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555; // Üzerine gelince rengi değiştir
  }
}
`;