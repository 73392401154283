import React from "react";
import { Box, Building2, Calculator, Clipboard, ClockArrowUp, Hotel, PencilLine, Pyramid, ReceiptEuro, Settings, ShoppingCart, SquareUser } from "lucide-react";

//burada dinamik icon import edip menuitemde kullanıcaz
//yapı şu şekilde olmalı örn icon.check, icon.close gibi 

const MenuIcon = {
    pencil: <PencilLine className="h-4 w-4" />,
    FrontOffice:<Hotel className="h-4 w-4"/>,
    Muhasebe:<Calculator className="h-4 w-4"/>,
    Stok:<Box className="h-4 w-4"/>,
    SatinAlma:<ShoppingCart className="h-4 w-4"/>,
    Demirbas:<Building2 className="h-4 w-4"/>,
    Personel:<SquareUser className="h-4 w-4"/>,
    Crm:<Pyramid className="h-4 w-4"/>,
    Pos:<ReceiptEuro className="h-4 w-4"/>,
    Bakim:<ClockArrowUp className="h-4 w-4"/>,
    Kalite:<Clipboard className="h-4 w-4"/>,
    Ayarlar:<Settings className="h-4 w-4"/>
}
export default MenuIcon;