import React, { useState, useEffect, ReactNode, useMemo, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { IconType } from "react-icons";
import * as Icons from "react-icons/sl";
import { menuData } from "../../Config/menuItems";
import { MenuItem } from "../../Types/MenuItem";
import "./Sidebar.scss";
import { FiChevronDown } from "react-icons/fi";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import clickSoundFile from "../../assets/sounds/mixkit-metal-button-push-1830.wav";
import clickSoundFiledropdown from "../../assets/sounds/lighter-click-271118.mp3";
import {
  ActiveMenuItem,
  ActiveSubmenu,
  Defamenu,
  SubhidMenu,
  SubhidMenuBg,
  Submenu,
} from "./StyledSidebar";
import { useTheme } from "../../Context/ThemeContext";
import { usePermissions } from "../../Context/PermissionContext/PermissionContext";
import { Star,ScanHeart } from "lucide-react";

interface SidebarProps {
  menuState: "hidden" | "subhidden" | "default";
  setMenuState: (state: "hidden" | "subhidden" | "default") => void;
}

const getIcon = (iconName: string): React.ElementType | null => {
  const IconComponent = (Icons as Record<string, React.ElementType>)[iconName];
  return IconComponent || null;
};

const Sidebar: React.FC<SidebarProps> = ({ menuState, setMenuState }) => {
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState<MenuItem | null>(null);
  const [openSubmenus, setOpenSubmenus] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [subMenuActive, setSubMenuActive] = useState<string>("");
  const { themeColors, borderRadius, isDarkMode, setColor, setHoverColor } =
    useTheme();
  const [activeParentId, setActiveParentId] = useState<string>(""); // Yeni durum
  const [hizliMenuState, setHizliMenuState] = useState<MenuItem[]>(
    JSON.parse(localStorage.getItem("hizliMenuState") || "[]")
  );
  const audio = new Audio(clickSoundFile); // Ses dosyasını yükle
const audioDropdown = new Audio(clickSoundFiledropdown); // Ses dosyasını yükle
  const playClickSound = () => {
    audio.currentTime = 0; // Sesi baştan çal
    audio.play();
  };
  const playClickSoundDropdown = () => {
    audioDropdown.currentTime = 0; // Sesi baştan çal
    audioDropdown.play();
  };

  useEffect(() => {
    localStorage.setItem("hizliMenuState", JSON.stringify(hizliMenuState));
  }, [hizliMenuState]);
  const { pagePermissions, buttonPermissions } =  usePermissions();
  // console.log("pagePermissions",pagePermissions);
  // console.log("buttonPermissions",buttonPermissions);
  const initialRender = useRef(true); // initialRender değişkeni useRef ile tanımlanıyor

// //sadece pagePermissions alınacak 
  const [userPermissions, setUserPermissions] = useState<string[]>([]); // Yerel state



useEffect(() => {
    setUserPermissions(pagePermissions);
}, [pagePermissions]); // Boş bağımlılık listesi




  // filter data
  // const userPermissions = [
  //   "change_password",
  //   "edit_privacy",
  //   "edit_profile",
  //   "manage_email_notifications",
  //   "manage_push_notifications",
  //   "two_factor_auth",
  //   "view_annual_sales",
  //   "view_custom_sales",
  //   "view_customer_report",
  //   "view_customer_retention",
  //   "view_dashboard",
  //   "view_login_activity",
  //   "view_monthly_sales",
  //   "view_new_customers",
  //   "view_notifications",
  //   "view_profile",
  //   "view_profile_details",
  //   "view_reports",
  //   "view_sales_report",
  //   "view_security",
  //   "view_settings",
  //   "view_kullanici_kontrolleri",
  //   "all_permissions", // Tüm yetkileri temsil eden özel anahtar
  // ];

  // Güncellenmiş filterMenuByPermissions fonksiyonu
  const filterMenuByPermissions = (
    menu: MenuItem[],
    permissions: string[]
  ): MenuItem[] => {
    // Eğer "all_permissions" varsa, tüm menü öğelerini döndür
    if (permissions.includes("all_permissions")) {
      return menu.map((item) => ({
        ...item,
        children: item.children
          ? filterMenuByPermissions(item.children, permissions)
          : undefined,
      }));
    }

    // Normal filtreleme
    return menu
      .filter(
        (item) =>
          item.requiredPermission &&
          permissions.includes(item.requiredPermission)
      )
      .map((item) => ({
        ...item,
        children: item.children
          ? filterMenuByPermissions(item.children, permissions)
          : undefined,
      }));
  };

  const filteredMenuData = filterMenuByPermissions(menuData, userPermissions);
  const hizliMenu: MenuItem = {
    id: "hizlimenu",
    label: "Hızlı Menü",
    level: 1,
    icon: <ScanHeart className="h-4 w-4"/>,
    componentUrl: "/hizlimenu",
    requiredPermission: "view_Hizlimenu",
    children: hizliMenuState,
  };

  const extendedMenuData = [hizliMenu, ...filteredMenuData];

  // filter data

  const addToHizliMenu = (menu: MenuItem, selectedChildId: string) => {
    /* console.log(menu);*/
    if (menu.level === 2) {
      // Seçilen Level 2 menü (örneğin Sales Report)
      const selectedChild = menu.children?.find(
        (child) => child.id === selectedChildId
      );

      if (selectedChild) {
        setHizliMenuState((prev) => {
          // Mevcut menüyü bul
          const existingMenu = prev.find((item) => item.id === menu.id);

          if (existingMenu) {
            // Eğer mevcut menü varsa, kontrol et: aynı çocuk zaten varsa çıkar
            const isChildAlreadyAdded = existingMenu.children?.some(
              (child) => child.id === selectedChildId
            );

            if (isChildAlreadyAdded) {
              // Çocuk çıkarılır
              const updatedChildren = existingMenu.children?.filter(
                (child) => child.id !== selectedChildId
              );

              // Eğer çocuklar boşsa, menüyü tamamen çıkar
              if (!updatedChildren?.length) {
                return prev.filter((item) => item.id !== menu.id);
              }

              // Güncellenmiş menüyü döndür
              const updatedMenu = {
                ...existingMenu,
                children: updatedChildren,
              };
              return [
                ...prev.filter((item) => item.id !== menu.id),
                updatedMenu,
              ];
            }

            // Çocuk henüz eklenmemişse, ekle
            const updatedMenu = {
              ...existingMenu,
              children: [
                ...(existingMenu.children || []),
                {
                  ...selectedChild,
                  componentUrl: `/hizlimenu${selectedChild.componentUrl}`, // Level 3 URL güncellemesi
                },
              ],
            };
            return [...prev.filter((item) => item.id !== menu.id), updatedMenu];
          } else {
            // Eğer mevcut menü yoksa, yeni bir menü oluştur ve ekle
            const newMenu = {
              ...menu,
              componentUrl: `/hizlimenu${menu.componentUrl}`, // Level 2 URL güncellemesi
              children: [
                {
                  ...selectedChild,
                  componentUrl: `/hizlimenu${selectedChild.componentUrl}`, // Level 3 URL güncellemesi
                },
              ],
            };
            return [...prev, newMenu];
          }
        });
      }
    } else if (menu.level === 3) {
      // Seçilen Level 3 menü
      const parentMenu = filteredMenuData.find((item) =>
        item.children?.some((child) => child.id === menu.id)
      );

      if (parentMenu) {
        setHizliMenuState((prev) => {
          const existingParent = prev.find((item) => item.id === parentMenu.id);

          if (existingParent) {
            // Mevcut parent menüyü kontrol et: aynı çocuk varsa çıkar
            const isChildAlreadyAdded = existingParent.children?.some(
              (child) => child.id === menu.id
            );

            if (isChildAlreadyAdded) {
              // Çocuk çıkarılır
              const updatedChildren = existingParent.children?.filter(
                (child) => child.id !== menu.id
              );

              // Eğer çocuklar boşsa, menüyü tamamen çıkar
              if (!updatedChildren?.length) {
                return prev.filter((item) => item.id !== parentMenu.id);
              }

              // Güncellenmiş parent menüyü döndür
              const updatedParent = {
                ...existingParent,
                children: updatedChildren,
              };
              return [
                ...prev.filter((item) => item.id !== parentMenu.id),
                updatedParent,
              ];
            }

            // Çocuk henüz eklenmemişse, ekle
            const updatedParent = {
              ...existingParent,
              children: [
                ...(existingParent.children || []),
                {
                  ...menu,
                  componentUrl: `/hizlimenu${menu.componentUrl}`, // Level 3 URL güncellemesi
                },
              ],
            };
            return [
              ...prev.filter((item) => item.id !== parentMenu.id),
              updatedParent,
            ];
          } else {
            // Eğer parent menü mevcut değilse yeni bir parent oluştur
            const newParent = {
              ...parentMenu,
              componentUrl: `/hizlimenu${parentMenu.componentUrl}`, // Parent URL güncellemesi
              children: [
                {
                  ...menu,
                  componentUrl: `/hizlimenu${menu.componentUrl}`, // Level 3 URL güncellemesi
                },
              ],
            };
            return [...prev, newParent];
          }
        });
      }
    }
  };
  const toggleLevel2InHizliMenu = (menu: MenuItem) => {
    if (menu.level !== 2) {
      console.error("This function only supports Level 2 menus.");
      return;
    }

    setHizliMenuState((prev) => {
      const existingMenu = prev.find((item) => item.id === menu.id);

      if (existingMenu) {
        // If the Level 2 menu exists, remove it entirely
        return prev.filter((item) => item.id !== menu.id);
      }

      // If the Level 2 menu doesn't exist, add it with all its children
      const newMenu = {
        ...menu,
        componentUrl: `/hizlimenu${menu.componentUrl}`, // Update Level 2 URL
        children: (menu.children || []).map((child) => ({
          ...child,
          componentUrl: `/hizlimenu${child.componentUrl}`, // Update Level 3 URLs
        })),
      };

      return [...prev, newMenu];
    });
  };

  const isInHizliMenu = (menuId: string) => {
    return hizliMenuState.some(
      (item) =>
        item.id === menuId ||
        item.children?.some((child) => child.id === menuId)
    );
  };

  useEffect(() => {
    const currentPath = location.pathname;

    extendedMenuData.forEach((menu) => {
      if (menu.componentUrl === currentPath) {
        setSelectedMenu(menu);
        setMenuState(menu.children ? "default" : "subhidden");
      } else if (menu.children) {
        menu.children.forEach((child) => {
          if (child.componentUrl === currentPath) {
            setSelectedMenu(menu);
            setSubMenuActive(child.id);
            setMenuState("default");
            setOpenSubmenus((prev) => ({ ...prev, [menu.id]: true }));
          } else if (child.children) {
            child.children.forEach((subChild) => {
              if (subChild.componentUrl === currentPath) {
                setSelectedMenu(menu);
                setSubMenuActive(subChild.id);
                setActiveParentId(child.id); // Aktif üst menü öğesini ayarlıyoruz
                setMenuState("default");
                setOpenSubmenus((prev) => ({
                  ...prev,
                  [menu.id]: true,
                  [child.id]: true,
                }));
              }
            });
          }
        });
      }
    });
  }, [location, setMenuState]);

  const handleMenuClick = (menu: MenuItem) => {
    // console.log(menuState)
    playClickSound(); // Menü tıklandığında ses çal
    setSelectedMenu(menu);

    if (menu.children) {
      setMenuState("default");
      setOpenSubmenus((prev) => ({
        ...prev,
        [menu.id]: !prev[menu.id],
      }));
    } else {
      setMenuState("subhidden");
    }
  };

  const toggleSubmenu = (menuId: string) => {
    playClickSoundDropdown();
    setOpenSubmenus((prev) => ({
      ...prev,
      [menuId]: !prev[menuId],
    }));
  };
  
  if (!userPermissions || userPermissions.length === 0) {
    return null; // Hiçbir izin yoksa Sidebar hiç render edilmez
  }
  
  return (
    <div className="sidebar">
      <div
        className="flex subhid-menu-cont transition-all duration-300 text-xs  bg-gray-800"
        style={{ background: themeColors.bg2, height: "94.3vh" }}
      >
        <SubhidMenuBg
          bgColor={themeColors.bg1}
          color={themeColors.color}
          className={`${
            menuState === "default" || menuState === "subhidden"
              ? "w-[6.3rem]"
              : "w-0"
          } transition-width duration-300 pt-2 shadow-lg`}
        >
          {menuState !== "hidden" && (
            <div>
              <ul>
                {extendedMenuData.map((item) => {
                  // const IconComponent = getIcon(item.icon);
                  const isActive = selectedMenu && selectedMenu.id === item.id;

                  return (
                    <SubhidMenu
                      key={item.id}
                      color={themeColors.color}
                      hoverColor={themeColors.hover}
                      borderColor={themeColors.bg3}
                    >
                      <ActiveMenuItem
                        isActive={!!isActive}
                        onClick={() => handleMenuClick(item)}
                        activeBgColor={themeColors.bg2}
                        activeColor={themeColors.hover}
                        lineColor={themeColors.hover}
                      >
                        {item.icon}
                        {/* {IconComponent && (
                          <IconComponent className=" text-sm" />
                        )} */}
                        {!item.children ? (
                          <Link to={item.componentUrl}>{item.label}</Link>
                        ) : (
                          <div>{item.label}</div>
                        )}
                      </ActiveMenuItem>
                    </SubhidMenu>
                  );
                })}
              </ul>
            </div>
          )}
        </SubhidMenuBg>
        <Defamenu
          borderColor={themeColors.bg3}
          bgColor={themeColors.bg1}
          boxShadow={themeColors.boxShadow}
          color={themeColors.color}
          className={`${
            menuState === "default" ? "w-64" : "w-0"
          }  transition-width duration-300 text-sm  pb-4`}
          style={{
            zIndex: "999",
            borderTopRightRadius: borderRadius === "rounded" ? "24px" : "0px",
            borderBottomRightRadius:
              borderRadius === "rounded" ? "24px" : "0px",
          }}
        >
          {menuState === "default" && selectedMenu && (
            <div className="px-2 d-menu overflow-auto h-full	">
              <ul className="mt-4">
                {selectedMenu?.children?.map((child) => {
                  // const ChildIconComponent = getIcon(child.icon);

                  return (
                    <li
                      key={child.id}
                      className="mb-2 "
                      style={{
                        color:
                          activeParentId === child.id
                            ? themeColors.hover
                            : themeColors.color,
                      }}
                      
                    >
                      <Submenu
                        hoverColor={themeColors.hover}
                        onClick={() => {
                          if (child.children && child.children.length > 0) {
                            toggleSubmenu(child.id);
                          }
                        }}
                        style={{
                          color:
                            location.pathname === child.componentUrl
                              ? themeColors.hover // Aktif olduğunda yazı rengi
                              : themeColors.color, // Normal yazı rengi
                        }}
                        className=" cursor-pointer flex items-center w-full"
                      >
                        {/* Menü adı ve FiChevronDown ikonu yan yana */}
                        <div className="flex items-center">
                          {child.children && child.children.length > 0 ? (
                            <>
                              <FiChevronDown
                                size={15}
                                className={`transition-transform duration-300 mr-2 ${
                                  openSubmenus[child.id]
                                    ? "rotate-180"
                                    : "rotate-0"
                                }`}
                              />
                              <div>{child.label}</div>
                            </>
                          ) : (
                            // Eğer children yoksa, doğrudan bir bağlantı olarak davran
                            <Link
                              to={child.componentUrl}
                              className="flex items-center"
                            >
                              <div>{child.label}</div>
                            </Link>
                          )}
                        </div>

                        {/* Kalp İkonu */}
                        <div className="ml-auto">
                          {isInHizliMenu(child.id) ? (
                            <FaHeart
                              onClick={() => toggleLevel2InHizliMenu(child)}
                              className="cursor-pointer"
                              color={themeColors.color}
                              size={12}
                            />
                          ) : (
                            <FaRegHeart
                              onClick={() => toggleLevel2InHizliMenu(child)}
                              className="cursor-pointer"
                              color={themeColors.color}
                              size={12}
                            />
                          )}
                        </div>
                      </Submenu>

                      <div
                        className={`submenu-content-wrapper ${
                          openSubmenus[child.id] ? "submenu-open" : ""
                        }`}
                      >
                        {child.children && (
                          <ul className="ml-4 mt-2">
                            {child.children.map((subChild) => {
                              // const SubChildIconComponent = getIcon(
                              //   subChild.icon
                              // );
                              const isSubActive =
                                location.pathname === subChild.componentUrl;

                              return (
                                <ActiveSubmenu
                                  key={subChild.id}
                                  onClick={() => setSubMenuActive(subChild.id)}
                                  isActive={isSubActive}
                                  activeColor={themeColors.hover}
                                  dotColor={themeColors.hover}
                                  hoverColor={themeColors.hover}
                                  color={themeColors.color}
                                  className=" ml-2 flex items-center"
                                >
                                  {/* Menü adı ve ikon yan yana */}
                                  <div className="flex items-center">
                                    {subChild.icon}
                                    {/* {SubChildIconComponent && (
                                      <SubChildIconComponent
                                        size={12}
                                        className="mr-2 text-lg"
                                      />
                                    )} */}
                                    <Link to={subChild.componentUrl} className="ml-2">
                                      {subChild.label}
                                    </Link>
                                  </div>

                                  {/* Kalp ikonu menünün en sağında sabit */}
                                  <div className="ml-auto">
                                    {isInHizliMenu(subChild.id) ? (
                                      <FaHeart
                                        onClick={() =>
                                          addToHizliMenu(child, subChild.id)
                                        }
                                        className="cursor-pointer"
                                        color={themeColors.color}
                                        size={12}
                                      />
                                    ) : (
                                      <FaRegHeart
                                        onClick={() =>
                                          addToHizliMenu(child, subChild.id)
                                        }
                                        className="cursor-pointer"
                                        color={themeColors.color}
                                        size={12}
                                      />
                                    )}
                                  </div>
                                </ActiveSubmenu>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </Defamenu>
      </div>
    </div>
  );
};

export default Sidebar;
