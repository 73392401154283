import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    ReactNode,
  } from "react";
  import { useAuth } from "../AuthContext/AuthContext";
  
  interface SessionTimeoutContextProps {
    setLogoutTime: (time: string | null) => void; // "HH:mm" formatında çıkış zamanı veya null
  }
  
  const SessionTimeoutContext = createContext<SessionTimeoutContextProps | undefined>(undefined);
  
  export const SessionTimeoutProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { logout } = useAuth();
    const [logoutTime, setLogoutTimeInternal] = useState<string | null>(null);
  
    // Logout zamanı ayarlayıp 5 dakika ekler veya sınırsız yapar
    const setLogoutTime = (time: string | null) => {
      if (time === null) {
        // console.log("Session set to unlimited.");
        setLogoutTimeInternal(null);
        return;
      }
  
      const [hours, minutes] = time.split(":").map(Number);
  
      // Çıkış saatine 5 dakika tolerans ekle
      const adjustedTime = new Date();
      adjustedTime.setHours(hours, minutes + 5, 0, 0);
  
      const adjustedLogoutTime = `${adjustedTime.getHours()}:${String(
        adjustedTime.getMinutes()
      ).padStart(2, "0")}`;
  
      // console.log(`Adjusted Logout Time Set to: ${adjustedLogoutTime}`);
      setLogoutTimeInternal(adjustedLogoutTime);
    };
  
    useEffect(() => {
      if (!logoutTime) {
        // console.log("Session is unlimited. No logout timer.");
        return;
      }
  
      const intervalId = setInterval(() => {
        const now = new Date();
        const currentTime = `${now.getHours()}:${String(now.getMinutes()).padStart(2, "0")}`;
  
        // console.log(`Current Time: ${currentTime}, Logout Time: ${logoutTime}`);
  
        const logoutDate = parseTime(logoutTime);
        const currentDate = parseTime(currentTime);
  
        const diffMinutes = (logoutDate.getTime() - currentDate.getTime()) / 60000;
  
        // console.log(`Time Difference: ${diffMinutes}`); // Farkı logla
  
        // Çıkış zamanı dolduğunda
        if (diffMinutes <= 0) {
          // console.log("Session time expired. Logging out...");
          logout();
          clearInterval(intervalId);
        }
        // Son 5 dakika içinde uyarı ver
        else if (diffMinutes <= 5 && diffMinutes > 0) {
          const minutesLeft = Math.ceil(diffMinutes);
          // console.log(`Warning: ${minutesLeft} minute(s) left.`);
          alert(`${minutesLeft} minute(s) left before session ends.`);
        }
      }, 60000); // Her dakika kontrol
  
      return () => clearInterval(intervalId);
    }, [logoutTime, logout]);
  
    const parseTime = (time: string) => {
      const [hours, minutes] = time.split(":").map(Number);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0);
      return date;
    };
  
    return (
      <SessionTimeoutContext.Provider value={{ setLogoutTime }}>
        {children}
      </SessionTimeoutContext.Provider>
    );
  };
  
  export const useSessionTimeout = () => {
    const context = useContext(SessionTimeoutContext);
    if (!context) {
      throw new Error("useSessionTimeout must be used within a SessionTimeoutProvider");
    }
    return context;
  };
  