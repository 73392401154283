import styled, { css,keyframes  } from "styled-components";

interface SubhidMenuProps {
  color?: string;
  hoverColor?: string;
  borderColor?: string;
}

interface DefamenuProps {
  borderColor?: string;
  bgColor?: string;
  boxShadow?: string;
  color?: string;
}
interface ActiveProps {
  activeBgColor?: string;
  activeColor?: string;
  activeLineColor?: string;
}
interface SubhidMenuProps {
  bgColor?: string;
  Color?: string;
}
interface SubmenuProps {
  hoverColor?: string;
}


interface ActiveMenuItemProps {
  isActive: boolean;
  activeBgColor?: string;
  activeColor?: string;
  lineColor?: string;
}

export const SubhidMenu = styled.div<SubhidMenuProps>`
  height: 60px;
  width: 100%;
  border-bottom: 1px solid ${({ borderColor }) => borderColor || "#2d3748"};
  transition: background-color 0.3s ease;
  li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
    color: ${({ color }) => color || "#a0aec0"};
    font-weight: 500;
    transition: color 0.3s ease;

    &:hover {
      color: ${({ hoverColor }) => hoverColor || "#63b3ed"};
    }

    .mr-2 {
      margin-right: 8px;
    }

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        color: inherit;
      }
    }
  }
`;

export const SubhidMenuBg = styled.div<SubhidMenuProps>`
  background-color: ${({ bgColor }) =>
    bgColor || "#ffffff"}; // Varsayılan olarak beyaz
  color: ${({ Color }) => Color || ""};
`;

export const Defamenu = styled.div<DefamenuProps>`
  border-left: 1px solid ${({ borderColor }) => borderColor || "#2d3748"};
  background-color: ${({ bgColor }) => bgColor || "#2d3748"};
  color: ${({ color }) => color || "#ffffff"}; // Varsayılan olarak beyaz
  transition: background-color 0.3s ease, width 0.3s ease;
  box-shadow: ${({ boxShadow }) =>
    boxShadow || "-2px 0 8px rgba(0, 0, 0, 0.3)"};

  .d-menu {
  position: relative; // Yerinde sabit durması için
  box-sizing: border-box; // Kenar boşlukları dahil
  scrollbar-gutter: stable; /* Kaydırma çubuğu alanını sabitler */

  // Kaydırma çubuğu stilini özelleştirme
  &::-webkit-scrollbar {
    width: 12px; // Kaydırma çubuğu genişliği
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0; // Kaydırma çubuğu arka planı
        right: 8px;

  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; // Kaydırma çubuğu rengi
    border-radius: 6px; // Köşe yuvarlama
    border: 3px solid #f0f0f0; // Dış boşluk
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555; // Üzerine gelince rengi değiştir
  }
}
`;

export const Submenu = styled.div<SubmenuProps>`
  transition: color 0.3s ease;
font-weight:bold;
  &:hover {
    color: ${({ hoverColor }) =>
      hoverColor || "#63b3ed"}; // Varsayılan hover yazı rengi
  }
`;

interface ActiveSubmenuProps {
  isActive: boolean;
  activeColor?: string;
  dotColor?: string;
  hoverColor?: string;
  color?:string;
}
export const ActiveSubmenu = styled.li<ActiveSubmenuProps>`
  position: relative;
  /* Alt menü öğesi için rengi doğrudan color propundan al */
  color: ${({ isActive, activeColor, color }) =>
    isActive ? activeColor : color || "inherit"} !important;

  &:hover {
    color: ${({ hoverColor }) =>
      hoverColor || "#63b3ed"} !important; /* Varsayılan hover yazı rengi */
  }

  ${({ isActive, dotColor }) =>
    isActive &&
    `
    &::before {
      content: '';
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      background-color: ${dotColor || "#3490dc"};
      border-radius: 50%;


    }
  `}
`;


const growAnimation = keyframes`
  0% {
    transform: translateY(-50%) scaleY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(-50%) scaleY(1);
    opacity: 1;
  }
`;

export const ActiveMenuItem = styled.li<ActiveMenuItemProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${({ isActive, activeBgColor }) =>
    isActive ? activeBgColor || "#2d3748" : "transparent"};
  ${({ isActive, activeColor }) =>
    isActive &&
    css`
      color: ${activeColor || "#63b3ed"} !important;
    `}
  transition: background-color 0.3s ease, color 0.3s ease;

  ${({ isActive, lineColor }) =>
    isActive &&
    css`
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%) scaleY(0);
        height: 60%;
        width: 4px;
        background-color: ${lineColor || "#63b3ed"};
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        opacity: 0;
        animation: ${growAnimation} 0.4s ease forwards;
      }
    `}
`;
// Aktif öğeyi temsil eden bir liste elemanı için stil
export const StyledListItem = styled.li<{ isActive: boolean; activeColor: string }>`
  color: ${({ isActive, activeColor }) => (isActive ? activeColor : "inherit")};
  margin-bottom: 0.5rem;

  &:hover {
    color: ${({ activeColor }) => activeColor};
  }
`;