import React from "react";
import { useTheme } from "../../Context/ThemeContext";
import { Scroolbar } from "../../assets/styles/Scrollbar/ScroolbarStyled";

interface ContentProps {
  children: React.ReactNode;
  menuState: "default" | "subhidden" | "hidden"; // Menü durumu prop olarak alınıyor
}

const Content: React.FC<ContentProps> = ({ children, menuState }) => {
  const { themeColors } = useTheme(); // Theme'den renkleri alıyoruz

  // Menü durumuna göre genişlik değerini belirliyoruz
  const getContentWidth = () => {
    switch (menuState) {
      case "default":
        return ""; // Sidebar açık
        // return "78.6vw"; // Sidebar açık
      case "subhidden":
        return "";
        // return "93.2vw"; // Sidebar küçültülmüş
      case "hidden":
        return "";
        // return "99vw"; // Sidebar gizlenmiş
      default:
        return "";
        // return "99vw";
    }
  };

  return (
    <div
      className="content"
      style={{ width: getContentWidth(), height: "calc(100vh - 60px)" }}
    >
      <div
        className="flex-1 p-2"
        style={{
          backgroundColor: themeColors.bg2, // Arka plan rengini theme'den alıyoruz
          color: themeColors.color, // Yazı rengini theme'den alıyoruz
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Content;
