import React, { useState, Suspense } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./Dashboard.scss";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Content from "../Content/Content";
import { componentMap } from "../../routes/routes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Scroolbar } from "../../assets/styles/Scrollbar/ScroolbarStyled";

const MySwal = withReactContent(Swal);

const Dashboard: React.FC = () => {
  const [menuState, setMenuState] = useState<
    "default" | "subhidden" | "hidden"
  >("hidden");
  const [subhiddenToHidden, setSubhiddenToHidden] = useState(true);

  const toggleMenuState = () => {
    setMenuState((prevState) => {
      if (prevState === "default") {
        setSubhiddenToHidden(true);
        return "subhidden";
      }
      if (prevState === "subhidden") {
        if (subhiddenToHidden) {
          setSubhiddenToHidden(false);
          return "hidden";
        } else {
          setSubhiddenToHidden(true);
          return "default";
        }
      }
      if (prevState === "hidden") return "subhidden";
      return "default";
    });
  };

  const location = useLocation();
  
  // "/hizlimenu" ön ekini kaldırmak için URL'yi işliyoruz
  const getProcessedPath = (path: string) => {
    if (path.startsWith("/hizlimenu")) {
      return path.replace("/hizlimenu", "");
    }
    return path;
  };

  return (
    <div className="dashboard h-screen flex flex-col overflow-hidden">
      {/* Header sabit kalır */}
      <Header toggleMenuState={toggleMenuState} />
      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar tam yüksekliği kaplar */}
        <Sidebar menuState={menuState} setMenuState={setMenuState} />
        {/* Content alanı scrollbar ile aşağı iner */}
        <Scroolbar className="flex-1 overflow-auto">
          <Content menuState={menuState}>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                {Object.keys(componentMap).map((path) => (
                  <Route
                    key={path}
                    path={path}
                    element={React.createElement(componentMap[path])}
                  />
                ))}
                {/* Hızlı Menü için dinamik eşleme */}
                {Object.keys(componentMap).map((path) => (
                  <Route
                    key={`/hizlimenu${path}`}
                    path={`/hizlimenu${path}`}
                    element={React.createElement(componentMap[path])}
                  />
                ))}
              </Routes>
            </Suspense>
          </Content>
        </Scroolbar>
      </div>
    </div>
  );
};

export default Dashboard;
