// src/routes/routes.ts
import React from "react"

const ComponentsComponent = React.lazy(
	() => import("../Components/ComponentsComponent")
)
const FullPageUIComponent = React.lazy(
	() => import("../Components/FullPageUIComponent")
)
const LayoutsComponent = React.lazy(
	() => import("../Components/LayoutsComponent")
)
const ScrollableComponent = React.lazy(
	() => import("../Components/ScrollableComponent")
)
const ChatComponent = React.lazy(() => import("../Components/ChatComponent"))
const KullaniciKontrolleri = React.lazy(
	() =>
		import(
			"../Pages/Ayarlar/GenelAyarlar/KullaniciKontrolleri/KullaniciKontrolleri"
		)
)
const MusteriTipiTanimlama = React.lazy(
	() =>
		import(
			"../Pages/FrontOffice/FrontOfficeAyarlari/MusteriTipiTanımlama/MusteriTipiTanımlama"
		)
)
const KonaklamaTipiTanimlama = React.lazy(
	() =>
		import(
			"../Pages/FrontOffice/FrontOfficeAyarlari/KonaklamaTipiTanimlama/KonaklamaTipiTanimlama"
		)
)

const OdaTipiTanimlama = React.lazy(
	() =>
		import(
			"../Pages/FrontOffice/FrontOfficeAyarlari/OdaTipiTanımlama/OdaTipiTanımlama"
		)
)
// const KullaniciKontrolleri = React.lazy(() => import('../signalr ile kulalnıcıyı atma/fetchActiveUsers'));

export const componentMap: {
	[key: string]: React.LazyExoticComponent<React.FC>
} = {
	"/dashboard": ComponentsComponent,
	"/settings/profile": FullPageUIComponent,
	"/settings/security": LayoutsComponent,
	"/reports/sales": ScrollableComponent,
	"/chat": ChatComponent,
	"/settings/general-settings/user-controls": KullaniciKontrolleri,
	"/front-office/settings/customer-type": MusteriTipiTanimlama,
	"/front-office/settings/acommodation-type": KonaklamaTipiTanimlama,
	"/front-office/settings/room-type": OdaTipiTanimlama,
	// Diğer rotalar burada tanımlanabilir...
}
