import React from "react";
import { useHotel } from "./HotelContext";
import Dropdown from "../../Components/Dropdown/Dropdown";

const HotelSelect: React.FC = () => {
  const { hotels, selectedHotel, setSelectedHotel } = useHotel();

  const handleSelectHotel = (otelId: number) => {
    const hotel = hotels.find((h) => h.otelId === otelId);
    if (hotel) {
      setSelectedHotel(hotel);
    }
  };

  return (
    <Dropdown
      selected={selectedHotel?.otelAdi || null}
      options={hotels.map((hotel) => ({ id: hotel.otelId, label: hotel.otelAdi }))}
      onSelect={handleSelectHotel}
    />
  );
};

export default HotelSelect;
