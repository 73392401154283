// Components/Loader/Loader.tsx
import React, { useEffect, useState } from 'react';
import { useTheme } from '../../Context/ThemeContext';
import logo from '../../images/lgoo.png'
interface LoaderProps {
  onFinish: () => void; // Yükleme tamamlandıktan sonra ana içeriğe geçiş işlevi
}

const Loader: React.FC<LoaderProps> = ({ onFinish }) => {
  const { themeColors } = useTheme();
  const [showWelcome, setShowWelcome] = useState(false);

  useEffect(() => {
    // İlk 1 saniye yükleyici gösterilecek
    const loaderTimer = setTimeout(() => {
      setShowWelcome(true); // "Hoşgeldiniz" mesajını göstermek için
    }, 1000);

    // 1 saniye sonra "Hoşgeldiniz" mesajı gösterilecek ve ardından ana içeriğe geçilecek
    const finishTimer = setTimeout(() => {
      onFinish(); // Ana içeriğe geçiş
    }, 3000); // Toplamda 2 saniyelik gecikme

    return () => {
      clearTimeout(loaderTimer);
      clearTimeout(finishTimer);
    };
  }, [onFinish]);

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        background: themeColors.bg2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '24px',
        color: themeColors.color,
      }}
    >
      {!showWelcome ? (
        <>
          {/* Logo */}
          <img src={logo} alt="Logo" style={{ width: 100, height: 100, marginBottom: 20 }} />
          
          {/* Yükleniyor Metni */}
          <div style={{ marginBottom: 10 }}>Yükleniyor...</div>

          {/* Animasyonlu Dönen Yükleyici */}
          <div
            style={{
              border: `4px solid ${themeColors.color}`,
              borderTop: `4px solid transparent`,
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              animation: 'spin 0.5s linear infinite',
            }}
          />
        </>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {/* "Innovation Quasar Yazılım Çözümlerine Hoşgeldiniz" Mesajı (Animasyonlu) */}
          {'Innovation Quasar Yazılım Çözümlerine Hoşgeldiniz !'.split('').map((char, index) => (
            <span
              key={index}
              style={{
                opacity: 0,
                animation: `fadeIn 0.5s ease forwards ${index * 0.03}s`, // Hızlıca soldan sağa getir
                fontSize: '24px',
              }}
            >
              {char}
            </span>
          ))}
        </div>
      )}

      {/* CSS Animasyonları */}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          @keyframes fadeIn {
            to { opacity: 1; transform: translateX(0); }
          }
        `}
      </style>
    </div>
  );
};

export default Loader;
