import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useRef,
} from "react";
import { postRequest } from "../../api/apiService";
import apiPath from "../../api/ApiPath/ApiPath";
import { useHotel } from "../HotelContext/HotelContext";

type PermissionContextType = {
  pagePermissions: string[];
  buttonPermissions: string[];
  setPermissions: (permissions: {
    pagePermissions: string[];
    buttonPermissions: string[];
  }) => void;
  fetchPermissions: () => void;
  resetPermissions: () => void;
};

const PermissionContext = createContext<PermissionContextType | undefined>(
  undefined
);

const defaultPermissions = {
  pagePermissions: [],
  buttonPermissions: [],
};

export const PermissionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { selectedHotel } = useHotel();
  const [pagePermissions, setPagePermissions] = useState<string[]>(
    defaultPermissions.pagePermissions
  );
  const [buttonPermissions, setButtonPermissions] = useState<string[]>(
    defaultPermissions.buttonPermissions
  );
  const initialFetch = useRef(false);

  const setPermissions = (permissions: {
    pagePermissions: string[];
    buttonPermissions: string[];
  }) => {
    setPagePermissions(permissions.pagePermissions);
    setButtonPermissions(permissions.buttonPermissions);
  };

  const resetPermissions = () => {
    setPermissions(defaultPermissions);
  };
  
   const fetchPermissions = async () => {
    console.log("fetchPermissions çalıştı");
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    if (!selectedHotel?.key || !user.rolId) {
      console.error("Otel veya kullanıcı bilgisi eksik.");
      return;
    }

    try {
      const body = { rolId: user.rolId };
      const response = await postRequest("api2", apiPath.getYetki, body, {
        otelKey: selectedHotel.key,
      });

      setPermissions({
        pagePermissions: response?.sayfaYetkileri || [],
        buttonPermissions: response?.butonYetkileri || [],
      });
    } catch (error) {
      console.error("Yetkiler alınırken bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    if (selectedHotel?.key) {
      if (!initialFetch.current) {
        initialFetch.current = true;
        fetchPermissions();
      }
    }
  }, [selectedHotel]);

  return (
    <PermissionContext.Provider
      value={{ pagePermissions, buttonPermissions, setPermissions, fetchPermissions, resetPermissions }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermissions = () => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error("usePermissions must be used within a PermissionProvider");
  }
  return context;
};
