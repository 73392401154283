import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import axios from "axios";

interface AuthContextType {
  isAuthenticated: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  responseData: any; // API response verilerini saklamak için
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [responseData, setResponseData] = useState<any>(null); // Response verisini saklar

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const login = async (username: string, password: string) => {
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL_1 || "";
      const response = await axios.post(`${baseUrl}/api/auth/login`, { username, password });
      // console.log(response);
      const { token, expiration, permissions, groupId, user } = response.data;

      localStorage.setItem("authToken", token);
      localStorage.setItem("authExpiration", expiration);
      localStorage.setItem("authPermissions", JSON.stringify(permissions));
      localStorage.setItem("groupId", groupId);
      localStorage.setItem("user", JSON.stringify(user));

      setIsAuthenticated(true);
      setResponseData(response.data); // API response verisini sakla
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        throw new Error("Invalid username or password.");
      }
      throw new Error("An error occurred while logging in. Please try again.");
    }
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("authExpiration");
    localStorage.removeItem("authPermissions");
    localStorage.removeItem("groupId");
    localStorage.removeItem("user");
    setIsAuthenticated(false);
    setResponseData(null); // Logout sırasında response verisini temizle
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, responseData }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
