import React, { createContext, useState, useEffect, useContext } from 'react';

export interface ThemeColors {
  bg1: string;
  bg2: string;
  bg3: string;
  color: string;
  hover: string;
  boxShadow: string;
}

interface ThemeContextProps {
  themeColors: ThemeColors;
  setColor: (color: string) => void;
  setHoverColor: (hover: string) => void;
  toggleTheme: (isDarkMode: boolean) => void;
  borderRadius: 'rounded' | 'flat';
  setBorderRadius: (radius: 'rounded' | 'flat') => void;
  isDarkMode: boolean;
}

const lightTheme: ThemeColors = {
  bg1: '#fff',
  bg2: '#f8f8f8',
  bg3: '#E3E3E3',
  color: '#3a3a3a',
  hover: '#008ecc',
  boxShadow: '0 3px 30px rgba(0, 0, 0, .1), 0 3px 20px rgba(0, 0, 0, .1)',
};

const darkTheme: ThemeColors = {
  bg1: '#1e2022',
  bg2: '#18191b',
  bg3: '#555555',
  color: '#8f8f8f',
  hover: '#008ecc',
  boxShadow: '0 3px 30px rgba(0,0,0,.3),0 3px 24px rgba(0,0,0,.3)',
};

const ThemeContext = createContext<ThemeContextProps>({
  themeColors: lightTheme,
  setColor: () => {},
  setHoverColor: () => {},
  toggleTheme: () => {},
  borderRadius: 'rounded',
  setBorderRadius: () => {},
  isDarkMode: false,
});

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [themeColors, setThemeColors] = useState<ThemeColors>(lightTheme);
  const [borderRadius, setBorderRadius] = useState<'rounded' | 'flat'>('rounded');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [loading, setLoading] = useState(true); // yüklenme durumu

  // localStorage'dan tema ve renk bilgilerini yükleme
  useEffect(() => {
    const storedTheme = localStorage.getItem('isDarkMode');
    const storedColors = localStorage.getItem('themeColors');

    if (storedTheme !== null) {
      const darkMode = JSON.parse(storedTheme);
      setIsDarkMode(darkMode);
      setThemeColors(darkMode ? darkTheme : lightTheme);
    }

    if (storedColors) {
      setThemeColors(JSON.parse(storedColors));
    }

    setLoading(false); // Yüklenme tamamlandı
  }, []);

  const toggleTheme = (darkMode: boolean) => {
    setIsDarkMode(darkMode);
    setThemeColors(darkMode ? darkTheme : lightTheme);
    localStorage.setItem('isDarkMode', JSON.stringify(darkMode));
  };

  const setColor = (color: string) => {
    setThemeColors((prevColors) => ({ ...prevColors, color }));
  };

  const setHoverColor = (hover: string) => {
    setThemeColors((prevColors) => ({ ...prevColors, hover }));
  };

  // themeColors değiştiğinde localStorage güncelleme
  useEffect(() => {
    if (!loading) {
      localStorage.setItem('themeColors', JSON.stringify(themeColors));
    }
  }, [themeColors, loading]);

  // Yüklenme aşamasındaysa herhangi bir içerik render edilmez
  if (loading) {
    return null;
  }

  return (
    <ThemeContext.Provider
      value={{
        themeColors,
        setColor,
        setHoverColor,
        toggleTheme,
        borderRadius,
        setBorderRadius,
        isDarkMode,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
