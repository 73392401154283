import axios from "axios";

// Parametreye göre baseURL'i belirle
const getBaseURL = (apiType: string): string => {
  if (apiType === "api1") {
    return process.env.REACT_APP_API_BASE_URL_1 || "";
  } else if (apiType === "api2") {
    return process.env.REACT_APP_API_BASE_URL_2 || "";
  }
  throw new Error(`Geçersiz API tipi: ${apiType}`);
};

// Axios instance oluşturma
const createAxiosInstance = (apiType: string, extraHeaders?: Record<string, string>) => {
  const baseURL = getBaseURL(apiType);

  if (!baseURL) {
    throw new Error(`BASE_URL tanımlı değil. Lütfen .env dosyasını kontrol edin: ${apiType}`);
  }

  const instance = axios.create({
    baseURL,
    timeout: 10000, // 10 saniyelik timeout
  });

  // Token'ı localStorage'dan alacak bir fonksiyon
  const getToken = (): string | null => {
    return localStorage.getItem("authToken"); // Token "authToken" olarak saklanıyor
  };

  // Request Interceptor: Tüm isteklere token ve ekstra header ekle
  instance.interceptors.request.use(
    (config) => {
      const token = getToken(); // Token'ı çekiyoruz
      if (token) {
        config.headers = config.headers || {};
        (config.headers as Record<string, string>).Authorization = `Bearer ${token}`; // Bearer ekleniyor
      }

      if (extraHeaders) {
        config.headers = config.headers || {};
        Object.entries(extraHeaders).forEach(([key, value]) => {
          (config.headers as Record<string, string>)[key] = value;
        });
      }

      return config;
    },
    (error) => {
      console.error("İstek oluşturulurken hata:", error);
      return Promise.reject(error);
    }
  );

  // Response Interceptor: Yanıtları veya hataları işle
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error("API Hatası:", error.response || error.message);
      return Promise.reject(error.response?.data || error.message);
    }
  );

  return instance;
};

export default createAxiosInstance;