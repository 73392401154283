import React, { useState, useRef } from "react";
import { useAuth } from "../../Context/AuthContext/AuthContext";
import "./Login.scss";
import logo from "../../images/lgoo.png";
import { usePermissions } from "../../Context/PermissionContext/PermissionContext";

const LoginPage: React.FC = () => {
  const { login, responseData } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { fetchPermissions } = usePermissions();
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const handleLogin = async (e?: React.FormEvent) => {
    e?.preventDefault();
    if (!username || !password) {
      setError("Please fill in both fields.");
      return;
    }
    setError(null);
    setIsLoading(true);

    try {
      await login(username, password); // Login işlemi
      console.log(responseData);
      setTimeout(async () => {
        await fetchPermissions();
      }, 1000);
    } catch (err: any) {
      setError(err.message); // Gelen hatayı ayarla
    } finally {
      setIsLoading(false); // Yüklenme durumunu kaldır
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, field: "username" | "password") => {
    if (e.key === "Enter") {
      if (field === "username" && passwordInputRef.current) {
        passwordInputRef.current.focus(); // Şifre alanına geç
      } else if (field === "password") {
        handleLogin(); // Giriş işlemini başlat
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-cover bg-center login-bg p-8">
      <div className="bg-white/90 rounded-lg shadow-lg w-full max-w-4xl flex flex-col md:flex-row">
        {/* Sol taraf (Bilgilendirme alanı) */}
        <div className="md:w-2/5 p-8 bg-white text-white flex flex-col justify-center rounded-t-lg md:rounded-l-lg md:rounded-tr-none">
          <img src={logo} alt="" />
        </div>

        {/* Sağ taraf (Form alanı) */}
        <div className="md:w-3/5 p-8">
          <div className="flex flex-col">
            <h3 className="text-xl font-semibold mb-4">Login</h3>
            {/* Hata Mesajı */}
            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
            {/* Kullanıcı Adı Girişi */}
            <input
              type="text"
              placeholder="E-mail"
              className="border border-gray-300 p-3 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, "username")} // Enter tuşu kontrolü
              autoFocus
            />
            {/* Şifre Girişi */}
            <input
              type="password"
              placeholder="Password"
              className="border border-gray-300 p-3 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, "password")} // Enter tuşu kontrolü
              ref={passwordInputRef} // Şifre alanı referansı
            />
            {/* Login Butonu */}
            <button
              onClick={handleLogin}
              className="bg-blue-600 text-white py-3 rounded hover:bg-blue-700 transition"
              disabled={isLoading}
            >
              {isLoading ? "LOADING..." : "LOGIN"}
            </button>
            {/* Şifreyi Unuttum Linki */}
            <a
              href="#"
              className="text-blue-500 text-sm mt-4 text-right hover:underline"
            >
              Forgot password?
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
